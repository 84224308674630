import React from 'react'
import { Fade } from 'react-reveal'


export default function Header() {
  return (
    <div id='Header' className='Header'>
      <div className="container">
        <div className="header-info">
          <div>
            <Fade left>
              <h2>Эвакуатор в Ташкенте</h2>
              <p className='price'>Подача 20-40 минут</p>
              <p className='price-info'>Мы можем эвакуировать автомобили весом до 5 тонн</p>
            
              <div className='d-flex'>
                <a href="tel:+9989980777272">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_59_36)">
                      <path d="M10.0334 3.33341C10.6845 3.46046 11.283 3.77892 11.7521 4.24804C12.2212 4.71716 12.5397 5.31559 12.6667 5.96675M10.0334 0.666748C11.3862 0.817039 12.6478 1.42286 13.6109 2.38475C14.574 3.34664 15.1814 4.60742 15.3334 5.96008M14.6667 11.2801V13.2801C14.6675 13.4657 14.6294 13.6495 14.555 13.8196C14.4807 13.9898 14.3716 14.1425 14.2348 14.268C14.0979 14.3935 13.9364 14.4891 13.7605 14.5486C13.5847 14.608 13.3983 14.6301 13.2134 14.6134C11.1619 14.3905 9.19137 13.6895 7.46004 12.5667C5.84926 11.5432 4.48359 10.1775 3.46004 8.56675C2.33336 6.82755 1.6322 4.84741 1.41337 2.78675C1.39671 2.60239 1.41862 2.41659 1.4777 2.24116C1.53679 2.06574 1.63175 1.90454 1.75655 1.76783C1.88134 1.63112 2.03324 1.52189 2.20256 1.4471C2.37189 1.3723 2.55493 1.33359 2.74004 1.33341H4.74004C5.06357 1.33023 5.37723 1.4448 5.62254 1.65577C5.86786 1.86674 6.02809 2.15971 6.07337 2.48008C6.15779 3.12013 6.31434 3.74857 6.54004 4.35341C6.62973 4.59203 6.64915 4.85136 6.59597 5.10067C6.5428 5.34998 6.41928 5.57882 6.24004 5.76008L5.39337 6.60675C6.34241 8.27578 7.72434 9.65771 9.39337 10.6067L10.24 9.76008C10.4213 9.58084 10.6501 9.45731 10.8994 9.40414C11.1488 9.35097 11.4081 9.37039 11.6467 9.46008C12.2516 9.68578 12.88 9.84233 13.52 9.92675C13.8439 9.97243 14.1396 10.1356 14.3511 10.3851C14.5625 10.6346 14.6748 10.9531 14.6667 11.2801Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_59_36">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                    </svg>980777272
                  </button>
                </a>

                <a href="tel:+9989974801616">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_59_36)">
                        <path d="M10.0334 3.33341C10.6845 3.46046 11.283 3.77892 11.7521 4.24804C12.2212 4.71716 12.5397 5.31559 12.6667 5.96675M10.0334 0.666748C11.3862 0.817039 12.6478 1.42286 13.6109 2.38475C14.574 3.34664 15.1814 4.60742 15.3334 5.96008M14.6667 11.2801V13.2801C14.6675 13.4657 14.6294 13.6495 14.555 13.8196C14.4807 13.9898 14.3716 14.1425 14.2348 14.268C14.0979 14.3935 13.9364 14.4891 13.7605 14.5486C13.5847 14.608 13.3983 14.6301 13.2134 14.6134C11.1619 14.3905 9.19137 13.6895 7.46004 12.5667C5.84926 11.5432 4.48359 10.1775 3.46004 8.56675C2.33336 6.82755 1.6322 4.84741 1.41337 2.78675C1.39671 2.60239 1.41862 2.41659 1.4777 2.24116C1.53679 2.06574 1.63175 1.90454 1.75655 1.76783C1.88134 1.63112 2.03324 1.52189 2.20256 1.4471C2.37189 1.3723 2.55493 1.33359 2.74004 1.33341H4.74004C5.06357 1.33023 5.37723 1.4448 5.62254 1.65577C5.86786 1.86674 6.02809 2.15971 6.07337 2.48008C6.15779 3.12013 6.31434 3.74857 6.54004 4.35341C6.62973 4.59203 6.64915 4.85136 6.59597 5.10067C6.5428 5.34998 6.41928 5.57882 6.24004 5.76008L5.39337 6.60675C6.34241 8.27578 7.72434 9.65771 9.39337 10.6067L10.24 9.76008C10.4213 9.58084 10.6501 9.45731 10.8994 9.40414C11.1488 9.35097 11.4081 9.37039 11.6467 9.46008C12.2516 9.68578 12.88 9.84233 13.52 9.92675C13.8439 9.97243 14.1396 10.1356 14.3511 10.3851C14.5625 10.6346 14.6748 10.9531 14.6667 11.2801Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_59_36">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>974801616
                  </button>
                </a>

                <a href="tel:+9989971115656">
                  <button> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_59_36)">
                        <path d="M10.0334 3.33341C10.6845 3.46046 11.283 3.77892 11.7521 4.24804C12.2212 4.71716 12.5397 5.31559 12.6667 5.96675M10.0334 0.666748C11.3862 0.817039 12.6478 1.42286 13.6109 2.38475C14.574 3.34664 15.1814 4.60742 15.3334 5.96008M14.6667 11.2801V13.2801C14.6675 13.4657 14.6294 13.6495 14.555 13.8196C14.4807 13.9898 14.3716 14.1425 14.2348 14.268C14.0979 14.3935 13.9364 14.4891 13.7605 14.5486C13.5847 14.608 13.3983 14.6301 13.2134 14.6134C11.1619 14.3905 9.19137 13.6895 7.46004 12.5667C5.84926 11.5432 4.48359 10.1775 3.46004 8.56675C2.33336 6.82755 1.6322 4.84741 1.41337 2.78675C1.39671 2.60239 1.41862 2.41659 1.4777 2.24116C1.53679 2.06574 1.63175 1.90454 1.75655 1.76783C1.88134 1.63112 2.03324 1.52189 2.20256 1.4471C2.37189 1.3723 2.55493 1.33359 2.74004 1.33341H4.74004C5.06357 1.33023 5.37723 1.4448 5.62254 1.65577C5.86786 1.86674 6.02809 2.15971 6.07337 2.48008C6.15779 3.12013 6.31434 3.74857 6.54004 4.35341C6.62973 4.59203 6.64915 4.85136 6.59597 5.10067C6.5428 5.34998 6.41928 5.57882 6.24004 5.76008L5.39337 6.60675C6.34241 8.27578 7.72434 9.65771 9.39337 10.6067L10.24 9.76008C10.4213 9.58084 10.6501 9.45731 10.8994 9.40414C11.1488 9.35097 11.4081 9.37039 11.6467 9.46008C12.2516 9.68578 12.88 9.84233 13.52 9.92675C13.8439 9.97243 14.1396 10.1356 14.3511 10.3851C14.5625 10.6346 14.6748 10.9531 14.6667 11.2801Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_59_36">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>971115656
                  </button>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
